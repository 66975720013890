.checkoutFooter {
    position: fixed;
    inset-inline: 0;
    bottom: 0;
    width: 100%;
    max-width: 1101px;
    margin-inline: auto;
    padding-inline: 24px;
    padding-block: 12px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px 24px 0px 0px;

    @media (min-width: 60rem) {
        padding-inline: 50px;
        position: absolute;
    }
}


.checkoutButton {
    padding: 0.9375rem;
    position: relative;
    font-size: 1rem;
    font-style: italic;
    border-radius: 12px;
    font-weight: 700;
    text-transform: uppercase;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    max-width: 150px;
    width: 100%;
    line-height: 1.25;

    @media (min-width: 60rem) {
        max-width: 222px;
        font-size: 1.1875rem;
    }
}

.checkoutButton :global(.StripeCheckout) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.checkoutButtonPrimary {
    background: #01243C;
    color: white;
    border: none;

}

.checkoutButtonSecondary {
    background: transparent;
    border: 1px solid #01243C;
}

.checkoutButtonHide {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.checkoutButtonLoading {
    position: absolute;
    z-index: 10;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}